<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('lbl.asociarHotels')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-contrate-update' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.asociate') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                v-if="model.proveedor_id"
                cols="12"
                md="4"
              >
                <p class="mt-3">
                  <strong class="ml-10">{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.identificador"
                  :label="$t('lbl.identificador')"
                  class="pt-2"
                  outlined
                  dense
                  hide-details
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="model.stopSale"
                  :label="$t('lbl.stopSale')"
                  hide-details
                  class="mt-2"
                  disabled
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="model.cancelado"
                  :label="$t('lbl.canceled')"
                  hide-details
                  class="mt-2"
                  disabled
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <!--HOTELS-->
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('lbl.hotels') }}</strong>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <AsociarHotels :id-exclude="idExclude" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus, mdiArrowLeft, mdiSquareEditOutline, mdiDeleteOutline,
} from '@mdi/js'

import AsociarHotels from '../utils/AsociarHotels.vue'

export default {
  components: {
    AppCardCode,
    AsociarHotels,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
      },
      model: {
        on_request: 0,
        calcule_tarif_all_day_by_first: true,
      },
      loading: false,
      tarifasAll: [],
      clasesAll: [],
      tarifasEdit: [],
      operadores: [],
      itemsOperador: [],
      searchOperador: null,
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
      suplementos: [],
      rules: {
        required: value => !!value || 'Required.',
        // eslint-disable-next-line no-restricted-globals
        numeric: value => !isNaN(value) || 'Invalid value.',
        counter: value => parseInt(value, 10) <= 100 || 'Number between 1 and 100',
      },
      searchMarca: null,
      marca_id: null,
      itemsMarca: [],
      modelos: [],
      allModelos: [],
      numCategorias: 0,
      catObjects: [],
      brandObjects: [],
      categories: [],
      deletingCat: false,
      seasonObjects: [],
      numSeasons: 0,
      numMarcaModelo: 0,
      modelo_id: null,
      marcas: [],
      oficinas: [],
      editarItemSuplemento: false,
      posEditarItemSuplemento: -1,
      initTipoContrate: null,
      isDialogDeletedataTipoContrate: false,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      condicionesGenerales_id: [],
      idExclude: [],
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      observationsHotel: state => state.app.observationsHotel,
      idsHotelsAsociados: state => state.app.idsHotelsAsociados,
    }),
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
  },

  created() {
    this.getItem()
  },

  methods: {
    ...mapMutations([
      'addObservationContrateHotel',
      'updateObservationContrateHotel',
      'updateIdsHotelsAsociadosContrate',
    ]),
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.hotels_pactados) {
              this.idExclude = this.model.hotels_pactados
            }

            /* if (this.model.operador_id) {
              this.itemsOperador.push(this.model.operador)
            } */

            /* if (this.model.proveedor_id) {
              this.itemsProveedor.push(this.model.proveedor)
            }

            if (this.model.hotels_pactados) {
              this.updateIdsHotelsAsociadosContrate(this.model.hotels_pactados)
            } else {
              this.updateIdsHotelsAsociadosContrate([])
            }

            if (this.model.observations) {
              this.updateObservationContrateHotel(this.model.observations)
            } else {
              this.updateObservationContrateHotel([])
            } */
          })
      } else {
        this.updateIdsHotelsAsociadosContrate([])
        this.updateObservationContrateHotel([])
        this.isLoading = false
        this.isLoadingSuplementos = false
      }
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.operadores = res.data.data
        })
    },
    getProveedores() {
      this.axios
        .get('afiliados/byproduct/hotels', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = res.data.data
        })
    },
    getSuplementos() {
      this.axios
        .get('nom_suplemento_contrate/hotels?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.suplementos = res.data.data
          }
        })
    },
    save() {
      const json = {
        hotels_pactados: this.idsHotelsAsociados,
      }

      if (this.idsHotelsAsociados.length > 0) {
        const proceder = true

        if (proceder) {
          this.loading = true
          if (sessionStorage.getItem('hotels-contrate-id') !== null) {
            this.axios
              .post(`contrate_hotels/asociar-hotels/${sessionStorage.getItem('hotels-contrate-id')}`, json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else {
                  this.updateIdsHotelsAsociadosContrate([])
                  this.$toast.success(this.$t('msg.infoSuccess'))
                  this.$router.push({ name: 'hotels-contrate-update' })
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
